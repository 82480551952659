import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { Link } from "gatsby";
import Modal from "react-bootstrap/Modal";
import { SafeHtmlParser } from "./safeHtmlParser";

const HomeHero = ({ title, video, ctaButton }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <section
      style={{ overflow: "hidden", maxHeight: "88vh" }}
      className="position-relative bg-white"
    >
      <Modal
        style={{ zIndex: 99999 }}
        show={modalShow}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-custom-width"
        centered
      >
        <Modal.Body className="modal-custom-height">
          <iframe
            loading="eager"
            style={{ width: "100%" }}
            frameBorder="0"
            height="100%"
            scrolling="no"
            src="https://jdk.repair/hosted/LhIMnjN4dpv7RSOu3_3KKH1voSkbbBk6?disableHeader=true&v=1"
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <p
            className="black-link inter-bold me-3 pointer"
            onClick={() => setModalShow(false)}
          >
            Close
          </p>
        </Modal.Footer>
      </Modal>
      <video
        className="video"
        loop
        autoPlay
        muted
        src={video}
        playsinline
        type="video/mp4"
      >
        Sorry, your browser doesn't support HTML5 video tag.
      </video>

      <Container
        style={{ zIndex: 2 }}
        className="position-absolute start-0 bottom-20"
      >
        <Row className=" pt-3 ps-lg-5">
          <Col className="pl-0 ">
            <h1
              className="pt-3 mb-3 text-white text-center text-md-start display-4"
              id="services-hero-title"
            >
              <SafeHtmlParser
                htmlContent={title}
              />
            </h1>
          </Col>
        </Row>
        <Row className="ps-lg-5 pb-5">
          <Col>
            <Button
              style={{ borderRadius: "0px" }}
              variant="secondary"
              as={Link}
              to={ctaButton.url}
              className="py-3 w-100 w-md-auto mb-4 mb-md-0 px-5 me-4"
            >
              {ctaButton.title}
            </Button>
            {/* <Button
							style={{ borderRadius: "0px" }}
							variant="outline-white"
							onClick={() => setModalShow(true)}
							className="py-3 w-100 w-md-auto px-5"
						>
							Upload your accident damage images
						</Button> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeHero;
