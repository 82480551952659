import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: wpMediaItem(title: { eq: "Family-Run-HP" }) {
					altText
					localFile {
						publicURL
					}
				}
				reviews {
					reviewCount
				}
				schemaLogo: file(relativePath: { eq: "icon.png" }) {
					publicURL
				}
			}
		`
	);

	const reviewCount = data.reviews.reviewCount;
	const localBusinessSchema = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		"@id": "https://www.ecfcarcare.co.uk",
		name: "ECF Car Care",
		legalName: "Eastbourne Coach Finishers Ltd",
		description:
			"ECF is the largest and longest-serving accident repair centre in Eastbourne East Sussex.",
		url: "https://www.ecfcarcare.co.uk",
		priceRange: "$$",
		areaServed: [
			{
				"@type": "City",
				name: "Eastbourne",
				sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
			},
		],
		email: "hello@ecfcarcare.co.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "7",
		location: {
			"@type": "City",
			name: "Eastbourne",
			sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
		},
		foundingLocation: {
			"@type": "City",
			name: "Eastbourne",
			sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
		},
		slogan:
			"Using manufacturer-approved parts and carrying out repairs to manufacturer standards, but for a lower price.",
		telephone: "+441323725220",
		address: {
			"@type": "PostalAddress",
			addressLocality: "Eastbourne",
			addressRegion: "East Sussex",
			postalCode: "BN23 6PE",
			streetAddress: "Birch Close",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "0.306323",
			longitude: "50.788935",
		},
		hasMap:
			"https://www.google.com/maps/place/ECF+Accident+Repair,+Servicing+%26+MOT+Centre./@50.788935,0.306323,16z/data=!4m5!3m4!1s0x0:0xdea4039e2c6fae37!8m2!3d50.7889353!4d0.3063231?hl=en",
		openingHours: "Mo,Tu,We,Th,Fr,Sa",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Monday",
				opens: "08:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "08:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "08:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Thursday",
				opens: "08:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Friday",
				opens: "08:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "11:30",
				dayOfWeek: "http://schema.org/Saturday",
				opens: "08:00",
			},
		],
		founder: {
			"@type": "Person",
			name: "Russell Turner",
			id: "https://www.ecfcarcare.co.uk/about-us",
			url: "https://www.ecfcarcare.co.uk/about-us",
			description:
				"Eastbourne Coach Finishers Ltd were founded in 1957 and were originally located in Eastbourne town centre. Tourism boomed in Eastbourne and along the south coast during the 50’s as people flocked to the beaches in vehicles from all over the country.",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "1",
			ratingValue: "4.9",
			reviewCount: `${reviewCount}`,
			url: "https://www.ecfcarcare.co.uk/reviews",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.ecfcarcare.co.uk/contact-us",
			url: "https://www.ecfcarcare.co.uk/contact-us",
			image: `${data.schemaLogo.publicURL}`,
			name: "Accident Repair Line",
			description: "Contact Page for ECF Car Care.",
			telephone: "+441323725220",
			contactType: "Accident Repair",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Eastbourne",
					sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
				},
			],
		},
		logo: `${data.schemaLogo.publicURL}`,
		image: `${data.heroImg.localFile.publicURL}`,
		sameAs: [
			"https://www.instagram.com/ecfcarcare/?hl=en",
			"https://twitter.com/ecfcarcare",
			"https://www.facebook.com/ECFCarRepairs",
			"https://www.linkedin.com/company/ecf-car-care/",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
