import React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";

const MobileServiceCard = ({ title, svg, url }) => {
	return (
		<Col xs={12} className="py-2 px-3">
			<div
				className="bg-white text-center py-5 px-4"
				style={{ boxShadow: "0px 3px 20px #00000029" }}
			>
				{svg}
				<p className="fs-2 d-block inter-bold py-4">{title}</p>
				<Button
					as={Link}
					to={url}
					variant="outline-background"
					className="btn py-3 px-md-7 w-100 w-md-auto mt-3"
					style={{ borderRadius: "0px" }}
				>
					Find out more
				</Button>
			</div>
		</Col>
	);
};

export default MobileServiceCard;
